













































































































































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventPayload,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { Component, Inject as VueInject, Mixins, Prop, Watch } from 'vue-property-decorator'

import {
  AllowedAttributes,
  AllowedImageRatio, AllowedImageWidth,
  AttributeValue,
  ProductData,
  ProductSneakPeakData,
  VariantSneakPeak
} from '../../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { ImageProps } from '../../../../../dsl/atoms/Image'
import { BadgeShape } from '../../../../../dsl/atoms/Badge'

import { DrawerType, IDrawer } from '../../../../shared/contracts/services'
import { DynamicContentAction } from '../../../../analytics/events/dynamicContent.event'
import { DynamicContentData } from '../../../../shared/contracts/dynamicContent'
import { DynamicContentMixin } from '../../../../shared/mixins/dynamicContent.mixin'
import { getUrlWithSyneriseCampaign } from '../../../../shared/helpers/syneriseCampaignPath'

import { UserModel } from '../../../../auth/shared'
import { ProductCartMixin } from '../../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../../shared'
import { ToastType } from '../../../../shared/services'
import { toImageProps } from '../../../../shared/support'

import { Modals } from '../../../config/modals'
import {
  translateProductVariantsToVariantsSwitch,
  VariantsSwitch,
  VariantsSwitchProps
} from '../../../molecules/VariantsSwitch'

import { attributesIconsRegistry, ProductCardConfig } from '../ProductCard.config'
import { AttributesParser } from '../partials/AttributesParser.vue'
import { isAttribute, translateProductToProductCard } from '../ProductCard.helpers'
import { AttributeData, ProductCardVariant, ResolvedProductCard } from '../ProductCard.contracts'
import {
  BaseWishListMixin,
  IBaseWishListMixin
} from '../../../../wishlist/shared/mixins/base.mixin'
import { IProductsRepository, ProductsRepositoryType } from '../../../contracts/repositories'
import GiftHelperMixin from '../../../../shared/mixins/gifts-helper.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductCardRich>({
  name: 'ProductCardRich',
  components: { AttributesParser, VariantsSwitch },
  async created (): Promise<void> {
    this.resolvedProduct = translateProductToProductCard(this.product, true)
    this.setActiveVariant()

    this.hasAllGiftsAvailable = await this.checkGiftsAvailability(this.activeVariant)
  },
  async mounted (): Promise<void> {
    this.checkIsFavourite()
    this.initComponentAttributes()

    /**
     * @inheritDoc
     */

    if (this.shouldAddToCart) {
      this.eventBus.handle('app:cart.remove', (event: EventPayload<AnyObject>) => {
        if (event.payload) {
          if (event.payload.items[0].id === this.activeVariant?.sku) {
            this.itemAdded = false
          }
        }
      })
    }
  }
})
export class ProductCardRich extends Mixins<
    GiftHelperMixin,
    ProductCartMixin,
    ToastMixin,
    DynamicContentMixin,
    IBaseWishListMixin>(
      GiftHelperMixin,
      ProductCartMixin,
      ToastMixin,
      DynamicContentMixin,
      BaseWishListMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  public configuration!: ProductCardConfig

  @Prop({ type: Boolean, required: false, default: false })
  public isGratis!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public isLazy!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isWishlist!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public shouldSortByPrice!: boolean

  @Prop({ type: Number, required: false })
  public readonly imageWidth?: number

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData | ProductSneakPeakData // todo: tu powinen byc ProductCardProps

  @Prop({ type: Array, required: true })
  public disabledBadgeIcons!: string[]

  @Prop({ type: Boolean, required: false, default: true })
  public withFavourite!: boolean

  @Prop({ type: Object, required: false, default: null })
  public readonly dynamicContentData!: DynamicContentData | null

  /**
   * TODO: Set default as true
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasButton!: boolean

  @Prop({ type: Array, required: false })
  public readonly mainAttributes!: string[]

  @Prop({ type: Array, required: false })
  public readonly additionalAttributes!: string[]

  @Prop({ type: String, required: false, default: 'medium' })
  public readonly modalSize?: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly useDrawer?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAddToCart?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldHaveCartBtn!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly useStarsRateAsForm!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAddDynamicContentSyneriseParam!: boolean

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null

  /**
   * Determines whether variant is in favourites
   */
  public isFavourite: boolean = false

  public isFavouriteLoading: boolean = false

  public isLoading: boolean = false

  public mainAttributesData: AttributeData[] | null = null

  public additionalAttributesData: AttributeData[] | null = null

  public hasAllGiftsAvailable: boolean = true

  public itemAdded: boolean = false

  public resolvedProduct: ResolvedProductCard | null = null

  public get massDetails (): string[] {
    if (!this.activeVariant) {
      return []
    }

    const details = []
    if (this.activeVariant.attributes.volumeName) {
      details.push('volumeName')
    }

    if (this.activeVariant.attributes.weightName) {
      details.push('weightName')
    }

    if (this.activeVariant.attributes.colorName) {
      details.push('colorName')
    }

    return details
  }

  /**
   * Determines whether product is a set.
   */
  public get isBundled (): boolean {
    const bundledProducts = this.getAttribute(AllowedAttributes.BundledProducts)
    return Array.isArray(bundledProducts) && bundledProducts.length > 0
  }

  public get wasItemAdded (): boolean {
    return this.itemAdded
  }

  public get wishlistBtnTitle (): string {
    return this.$t(`front.shared.wishlist.${this.isFavourite ? 'remove' : 'add'}`).toString()
  }

  /**
   * Description shortened to specific amount of characters
   */
  public get shortenedName (): string {
    const MAX_DESC_LENGTH = 30

    if (!this.activeVariant) {
      return ''
    }

    if (this.activeVariant.name.length) {
      if (this.activeVariant.name.length < MAX_DESC_LENGTH) {
        return this.activeVariant.name
      }

      return `${this.activeVariant.name.substring(0, MAX_DESC_LENGTH)}...`
    }

    return ''
  }

  /**
   * Checks whether all variants of the current product are unavailable
   */
  public get allVariantsUnavailable (): boolean {
    const temp = []
    for (const variant of Object.values(this.product.variants)) {
      if (variant.isAvailable) {
        temp.push(variant)
      }
    }

    return temp.length === 0
  }

  /**
   * Determines whether product has discount.
   */
  public get hasDiscount (): boolean {
    if (!this.activeVariant) {
      return false
    }

    return this.activeVariant.price.regularPrice > this.activeVariant.price.finalPrice
  }

  public get hasSellableQuantity (): boolean {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return false
    }

    return this.activeVariant.sellableQuantity > 0
  }

  /**
   * Determines whether product has variants.
   */
  public get hasVariants (): boolean {
    return this.variants.length > 0
  }

  /**
   * Define count of all variants (including unavailable)
   */
  public get variantsCount (): number {
    return this.variants.length
  }

  /**
   * Determines variant count suffixes
   */
  public get variantsCountSuffix (): string {
    if (!this.activeVariant?.identifier.variant_color) {
      return 'capacity'
    }

    return 'variant'
  }

  /**
   * Product's image.
   */
  public get productImage (): ImageProps | undefined {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant || !this.resolvedProduct) {
      return
    }

    const activeVariantHasImages = Array.isArray(this.activeVariant.images) && this.activeVariant.images.length > 0
    if (activeVariantHasImages) {
      return toImageProps(this.activeVariant.images[0], AllowedImageRatio.Square,
        AllowedImageWidth.ProductCard)
    }

    const variantWithImages = Object.values(this.resolvedProduct.variants).find((variant) => {
      return variant.images.length > 0
    })

    if (variantWithImages) {
      return toImageProps(variantWithImages.images[0], AllowedImageRatio.Square, AllowedImageWidth.ProductCard)
    }

    return { src: '', alt: '' }
  }

  public get productLine (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.ProductLine)
  }

  public get canAddDynamicParamToVariantUrl (): boolean {
    return !!this.dynamicContentData && typeof this.dynamicContentData.recommendationIds === 'object'
  }

  /**
   * Determines whether component has everything to be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasVariants && typeof this.activeVariant !== 'undefined'
  }

  /**
   * Translated (mapped) variants.
   */
  public translateProductVariantsToVariantsSwitch (type = 'color'): VariantsSwitchProps['variants'] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  public get variantUrlPath (): string {
    if (this.activeVariant && Object.values(this.product.variants).length > 1) {
      if (this.canAddDynamicParamToVariantUrl && this.shouldAddDynamicContentSyneriseParam) {
        return getUrlWithSyneriseCampaign(this.activeVariant.link, this.isUserPremium, this.dynamicContentData!)
      }

      return this.activeVariant.link
    }

    if (this.canAddDynamicParamToVariantUrl && this.shouldAddDynamicContentSyneriseParam) {
      return getUrlWithSyneriseCampaign(this.product.urlPath || '', this.isUserPremium, this.dynamicContentData!)
    }

    return this.product.urlPath || ''
  }

  /**
   * Determines product variants.
   */
  public get variants (): VariantSneakPeak<string>[] {
    return Object.values(this.product.variants)
  }

  /**
   * Return attributes with proper data based on AllowedAttribute type
   */
  public getAttributesData (attributes: string[]): AttributeData[] | null {
    const attr = []
    let candidateAttr = attributes

    if (!attributes || attributes.length === 0) {
      return null
    }

    if (!this.hasAllGiftsAvailable) {
      candidateAttr = candidateAttr.filter((attr) => attr !== AllowedAttributes.HasGift)
    }

    const iconsRegistry: Record<string, string> = Object.entries(attributesIconsRegistry)
      .reduce((acc, [key, value]) => {
        const disabledIcon = this.disabledBadgeIcons.find((icon) => icon === key)
        if (!disabledIcon) {
          return { ...acc, [key]: value }
        }

        return acc
      }, {})

    for (const value of Object.values(candidateAttr)) {
      /**
       * Check if attribute should have been rendered as icon
       */
      const icon = iconsRegistry[value]
      if (icon) {
        attr.push({
          value,
          // label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
          icon,
          shape: BadgeShape.Square,
          driver: 'icon'
        })
      } else {
        if (value === AllowedAttributes.IsSale && this.activeVariant) {
          attr.push({
            value,
            label: `-${100 - (Math.round((this.activeVariant.price.finalPrice / this.activeVariant.price.regularPrice) * 100))}%`,
            shape: BadgeShape.Square,
            driver: 'badge'
          })
        } else if (value === AllowedAttributes.HasGift || value === AllowedAttributes.IsSponsored) {
          attr.push({
            value,
            label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
            shape: BadgeShape.Rectangle,
            driver: 'badge',
            tooltip: value === AllowedAttributes.IsSponsored
              ? this.$t('front.products.organisms.productCard.attributes.sponsoredTooltip').toString()
              : undefined
          })
        } else {
          attr.push({
            value,
            label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
            shape: BadgeShape.Square,
            driver: 'badge'
          })
        }
      }
    }

    return attr
  }

  /**
   * Handles adding product to cart.
   */
  public async onAddToCart (disableAction: boolean = false): Promise<void> {
    if (!this.cartService) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isLoading = true
    this.itemAdded = true

    try {
      await this.addToCart(
        { ...this.activeVariant, description: this.productLine ?? '' },
        1,
        true,
        this.modalSize,
        this.isMobile() ? this.useDrawer : false,
        disableAction
      )
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Opens add review modal
   */
  public openProductReviewsModal (): void {
    if (!this.modalConnector) {
      return
    }

    let color = ''
    if (this.activeVariant && this.activeVariant?.identifier.type === 'color') {
      color = this.activeVariant.identifier.value
    }

    this.modalConnector.open(Modals.ProductReviewsModal, {
      color,
      description: this.activeVariant?.name,
      location: this.activeVariant?.link,
      rate: this.activeVariant?.rating,
      sku: this.activeVariant?.sku,
      title: this.activeVariant?.attributes.productLine,
      variants: this.translateProductVariantsToVariantsSwitch('color')
    })
  }

  /**
   * Handles update:model of variant switchers.
   */
  public onVariantSwitchUpdate (sku: string): void {
    this.setActiveVariant(sku)
  }

  /**
   * Sets the active variant.
   * @param sku - sku of the product.
   */
  public setActiveVariant (sku?: string): void {
    const variants =
      translateProductToProductCard(this.product, false, this.shouldSortByPrice).variants

    let _sku = sku

    if (this.isWishlist) {
      _sku = (this.wishlist?.items || []).find(wItem => {
        return variants.some(product => product.sku === wItem.product.sku)
      })?.product.sku
    }

    if (_sku) {
      this.activeVariant = variants.find((product) => product.sku === _sku)
    } else {
      this.activeVariant = variants[0]
    }
    this.checkIsFavourite()
  }

  /**
   * Changes (toggles) is favourite state of current variant
   */
  public async toggleFavourite (): Promise<void> {
    if (!this.isServiceAvailable) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavouriteLoading = true
    try {
      if (!this.isFavourite) {
        await this.add({
          quantity: 1,
          sku: this.activeVariant.sku
        })
        this.isFavourite = true
      } else {
        await this.remove(this.activeVariant.sku)
        this.isFavourite = false
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isFavouriteLoading = false
    }
  }

  /**
   * Triggered when clicking a content (product) that is dynamically chosen (e.g. by Synerise) should trigger DynamicContent event
   * @see DynamicContent
   */
  public onDynamicContentClick (): void {
    if (!this.dynamicContentData) {
      return
    }

    this.triggerDynamicContentEvent(this.dynamicContentData, DynamicContentAction.Click)
  }

  public onProductCardClick (): void {
    this.$emit('productCardClick')
    this.onDynamicContentClick()
  }

  /**
   * Checks the current variant if it is present in favourites list.
   */
  protected checkIsFavourite (): void {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavourite = this.isInWishlist(this.activeVariant.sku)
  }

  /**
   * Gets the attribute by key
   *
   * @param attribute - attribute key
   */
  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.activeVariant.attributes
      ? this.activeVariant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, level: ToastType): void {
    this.showToast(message, level)
  }

  /**
   * Set specific attributes for ProductCard from config
   * @param mainAttributes
   * @param additionalAttributes
   * @protected
   */
  protected setAttributeTypes (mainAttributes: string[], additionalAttributes: string[]): void {
    const main: string[] = []
    let additional: string[] = []

    if (!this.activeVariant) {
      this.mainAttributesData = this.getAttributesData(main)
      this.additionalAttributesData = this.getAttributesData(additional)
      return
    }

    for (const [attrKey, attrValue] of Object.entries(this.activeVariant.attributes)) {
      if (mainAttributes) {
        for (const value of Object.values(mainAttributes)) {
          if (value === attrKey) {
            // TODO: Should be better technique to check, because not always that we want are represented as boolean.
            if (value === attrKey && (attrValue === true || (Array.isArray(attrValue) && attrValue.length > 0))) {
              main.push(String(attrKey))
            }
          }
        }
      }

      if (additionalAttributes) {
        for (const value of Object.values(additionalAttributes)) {
          if (value === attrKey) {
            if (value === attrKey && attrValue === true) {
              additional.push(String(attrKey))
            }
          }
        }
      }
    }

    /** Add isSale attribute regarding current promotional price */
    if (!this.activeVariant.attributes.isSale && this.activeVariant.price.finalPrice < this.activeVariant.price.regularPrice) {
      main.push(AllowedAttributes.IsSale)
    }

    if (additional.includes(AllowedAttributes.HasGift) && !this.hasAllGiftsAvailable) {
      additional = additional.filter((attr) => attr !== AllowedAttributes.HasGift)
    }

    this.mainAttributesData = this.getAttributesData(main)
    this.additionalAttributesData = this.getAttributesData(additional)
  }

  /**
   * Init component's allowed attributes
   * @protected
   */
  protected initComponentAttributes (): void {
    this.setAttributeTypes(this.mainAttributes, this.additionalAttributes)
  }

  private get brand (): string | undefined {
    if (typeof this.getAttribute<string>(AllowedAttributes.Brand) === 'undefined') {
      return
    }

    return this.getAttribute<string>(AllowedAttributes.Brand)
  }

  @Watch('wishlist')
  private onWishlist (): void {
    if (this.wishlist) {
      this.checkIsFavourite()
    }
  }
}

export default ProductCardRich
