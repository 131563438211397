
























import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../../../contexts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { AttributeDriver } from '../ProductCard.config'
import { AttributeData } from '../ProductCard.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AttributesParser>({
  name: 'AttributesParser'
})
export class AttributesParser extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: false, default: 'badge' })
  public driver!: AttributeData['driver']

  @Prop({ type: String, required: false, default: '' })
  public label!: AttributeData['label']

  @Prop({ type: String, required: false, default: '' })
  public icon!: AttributeData['icon']

  @Prop({ type: String, required: false, default: '' })
  public value!: AttributeData['value']

  @Prop({ type: String, required: false, default: '' })
  public shape!: AttributeData['shape']

  @Prop({ type: String, required: false, default: '' })
  public tooltip!: AttributeData['tooltip']

  /**
   * Available attributes drivers
   */
  public driverType = AttributeDriver

  public tooltipOptions = {
    content: this.tooltip,
    trigger: this.isMobile() ? 'click' : 'hover'
  }

  /**
   * Check whether attribute should always be rendered in desktop size
   * @param attribute
   */
  public shouldRenderDesktopSize (attribute: string): boolean {
    const notAllowed = [AllowedAttributes.IsSponsored, AllowedAttributes.IsGift]
    return !notAllowed.includes(attribute as AllowedAttributes)
  }
}

export default AttributesParser
